<template>
  <div class="content">
    <el-form :inline="true" size="mini" id="search">
      <el-form-item label="社区名称">
        <el-input clearable v-model="search.communityName" class="w-150" placeholder="请输入社区名称"
          @input="listenKey()"></el-input>
      </el-form-item>
      <el-form-item label="学校名称">
        <el-input clearable class="w-150" v-model="search.schoolName" placeholder="请输入学校名称"
          @input="listenKey()"></el-input>
      </el-form-item>
      <!-- <el-form-item label="提交时间">
        <el-date-picker clearable class="w-250" v-model="search.createTime" type="daterange" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          @change="listenKey()"></el-date-picker>
      </el-form-item> -->
      <el-form-item>
        <el-button @click="getDataList()" type="warning" icon="el-icon-search">查询</el-button>
        <!-- <el-button type="danger" icon="el-icon-download" :loading="excelLoad" @click="exportExcels()">{{excelLoadtitle}}</el-button> -->
        <!--  <el-button
          type="danger"
          icon="el-icon-download"
          :loading="excelLoad"
          @click="exportExcelsNew()"
          >导出</el-button
        > -->
      </el-form-item>
    </el-form>
    <el-table :data="dataList" style="width: 100%;">
      <el-table-column prop="createTime" label="创建时间" align="center">
      </el-table-column>
      <el-table-column prop="province" label="省" align="center">
      </el-table-column>
      <el-table-column prop="city" label="市" align="center">
      </el-table-column>
      <el-table-column prop="area" label="区" align="center">
      </el-table-column>
      <el-table-column align="center" prop="check_type" label="查体类型" width="100"
        :filters="[{ text: '入托', value: '1' }, { text: '入园', value: '2' }, { text: '入校', value: '3' }, { text: '社区', value: '4' }]"
        :filter-method="filterTag" filter-placement="bottom-end">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.check_type == 1"
            :type="scope.row.check_type === '1' || scope.row.check_type === '4' ? 'primary' : 'success'"
            disable-transitions>入托</el-tag>
          <el-tag v-if="scope.row.check_type == 2"
            :type="scope.row.check_type === '1' || scope.row.check_type === '4' ? 'primary' : 'success'"
            disable-transitions>入园</el-tag>
          <el-tag v-if="scope.row.check_type == 3"
            :type="scope.row.check_type === '1' || scope.row.check_type === '4' ? 'primary' : 'success'"
            disable-transitions>入校</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="community_name" align="center" label="社区">
      </el-table-column>
      <el-table-column prop="school_name" align="center" label="学校">
      </el-table-column>
      <el-table-column prop="check_time" align="center" label="查体时间">
      </el-table-column>
      <el-table-column prop="total_num" align="center" label="总人数">
      </el-table-column>
      <el-table-column prop="question_num" align="center" label="检出人数">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="toSubmitData(scope.row.id)" size="mini" type="success" icon="el-icon-document"
            style="margin:5px">提交数据</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page_pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="search.pageNum"
        :page-sizes="[10]" :page-size="search.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog title="查体信息填写表单" :visible.sync="dialogInput" center :close-on-click-modal="false"
      :before-close="cancelDialog">
      <el-form :model="canshu" :rules="rules" ref="canshu" class="demo-form-inline" label-width="100px" size="medium"
        style="height: 100%; overflow-y: scroll">
        <el-form-item label="体检总人数" style="width: 100%" prop="totalNum">
          <el-input style="width: 100%" v-model="canshu.totalNum" placeholder="请输入体检总人数"></el-input>
        </el-form-item>
        <el-form-item label="检出总人数" style="width: 100%" prop="questionNum">
          <el-input style="width: 100%" v-model="canshu.questionNum" placeholder="请输入检出总人数"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog()" type="primary" icon="el-icon-circle-close">取消</el-button>
        <el-button @click="confirmDialog('canshu')" type="success" icon="el-icon-circle-check">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getTjListData, saveTjNum
} from "../../api/javaApi";
export default {
  components: {
    // saoma,
  },
  data() {
    return {
      search: {
        communityName: '',
        schoolName: '',
        pageNum: 1,
        pageSize: 10,
      },
      dialogInput: false,
      dataList: [],
      total: 0,

      communityName: '',
      schoolName: '',
      canshu: {
        questionNum: '',
        totalNum: '',
        id: ''
      },
      rules: {
        totalNum: [{ required: true, message: "请输入体检总人数", trigger: "blur" }],
        questionNum: [
          { required: true, message: "请输入检出总人数", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    //监听按键
    listenKey() {
      document.onkeydown = (e) => {
        /* let key = window.event.keyCode;
        if (key === 13) {
          this.pageList(this.pages.currentPage, this.pages.pageSize);
          document.onkeydown = undefined;
          // return false
        }*/
      };
    },
    async toSaveNum() {
      saveTjNum(this.canshu)
        .then((res) => {
          console.log(res)
          if (res.data.code != 200) {
            this.$message({
              type: "warning",
              message: res.data.message,
              center: true,
            });
          } else {
            this.canshu.id = ''
            this.dialogInput = false
            this.getDataList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirmDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.toSaveNum()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancelDialog() {
      this.dialogInput = false
      this.canshu.id = ''
    },
    toSubmitData(id) {
      this.canshu.id = id
      this.dialogInput = true
    },
    filterTag(value, row) {
      return row.tag === value;
    },
    handleSizeChange(val) {
      this.pageSize = val
      console.log(`每页 ${val} 条`);
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      console.log(`当前页: ${val}`);
      this.getDataList();
    },
    async getDataList() {
      var that = this;
      getTjListData(this.search)
        .then((res) => {
          console.log(res)
          if (res.data.code != 200) {
            that.$message({
              type: "warning",
              message: res.data.message,
              center: true,
            });
          } else {
            console.log(res.data.data.total)
            console.log(res.data.data.list)
            that.total = res.data.data.total;
            that.dataList = res.data.data.list;
            console.log(that.total)
            console.log(that.dataList)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.content {
  position: relative;
  padding: 30px;

  .page_pagination {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
</style>
